// src/components/SelectStateYear.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SelectStateYear = ({ onSubmit }) => {
  const [year, setYear] = useState('');
  const [state, setState] = useState('');
  const [years, setYears] = useState([]);  // List of years from the data
  const [states, setStates] = useState([]); // List of states for the selected year
  const [allData, setAllData] = useState([]); // Full data from API

  // Fetch the full data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.ballotquest.com/api/states');
        console.log("API response data:", response.data);  // Debugging output
        setAllData(response.data);

        // Extract unique years based on the actual data structure
        const uniqueYears = Array.from(new Set(response.data.map(item => item.year)));
        console.log("Extracted years:", uniqueYears);  // Debugging output
        setYears(uniqueYears);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Update states based on the selected year
  useEffect(() => {
    if (year) {
      const statesForYear = allData
        .filter(item => item.year === parseInt(year, 10))
        .map(item => item.name);
      setStates(statesForYear);
      setState(''); // Reset state when year changes
    } else {
      setStates([]);
    }
  }, [year, allData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(state, year);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Select Year:
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="">--Choose a year--</option>
          {years.map((availableYear, index) => (
            <option key={`year-${index}`} value={availableYear}>
              {availableYear}
            </option>
          ))}
        </select>
      </label>
      <label>
        Select State:
        <select value={state} onChange={(e) => setState(e.target.value)} disabled={!year}>
          <option value="">--Choose a state--</option>
          {states.map((stateName, index) => (
            <option key={`state-${index}`} value={stateName}>
              {stateName}
            </option>
          ))}
        </select>
      </label>
      <button type="submit" disabled={!state || !year}>Get Ballot Measures</button>
    </form>
  );
};

export default SelectStateYear;

