// src/api.js
import axios from 'axios';
import API_BASE_URL from './config';  // Use config.js for API base URL

export const fetchBallotMeasures = async (state, year) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/ballot-measures/${state}/${year}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching ballot measures:', error);
    return null;
  }
};

export const discussProposition = async (state, year, propindex, userMessage = '', conversationHistory = []) => {
  try {
    // Ensure userMessage is a string
    if (typeof userMessage !== 'string') {
      userMessage = '';
    }

    const response = await axios.post(
      `${API_BASE_URL}/discuss-measure/discuss/${state}/${year}/${propindex}`,
      {
        userMessage,
        conversationHistory,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in proposition discussion:', error);
    return null;
  }
};
