
// src/analytics.js
import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  ReactGA.initialize('G-39WQDB82TK');
};

const sendPageView = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export { initializeAnalytics, sendPageView };

