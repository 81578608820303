// src/pages/PropQuest.js

import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { discussProposition } from '../api';
import './PropQuest.css';

const PropQuest = () => {
  const location = useLocation();
  const { measure, year, state, propindex } = location.state || {};
  const [conversationHistory, setConversationHistory] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false); // State for tracking loading
  const inputRef = useRef(null); // Ref for input element

  // Initial load: Fetch summary for the proposition
  useEffect(() => {
    if (state && year && propindex != null) {
      discussProposition(state, year, propindex, "", []).then((data) => {
        const initialReply = data?.reply || 'Summary not available';
        setConversationHistory([{ role: "assistant", content: initialReply }]);
      });
    }
  }, [state, year, propindex]);

  // Ensure focus goes back to the input box when it becomes available
  useEffect(() => {
    if (!loading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [loading]);

  const handleUserMessageSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior
    if (loading || !userMessage.trim()) return; // Prevent if loading or input is empty
    setLoading(true); // Set loading to true when form is submitted

    const updatedHistory = [...conversationHistory, { role: "user", content: userMessage }];
    const data = await discussProposition(state, year, propindex, userMessage, updatedHistory);

    if (data?.reply) {
      setConversationHistory([...updatedHistory, { role: "assistant", content: data.reply }]);
      setResponse(data.reply);
    } else {
      setResponse('No response available');
    }

    setUserMessage(''); // Clear input after submission
    setLoading(false); // Set loading to false after response is received
  };

  return (
    <div className="container">
      <h2>Proposition Quest</h2>
      <p><strong>Selected Year:</strong> {year}</p>
      <p><strong>State:</strong> {state}</p>
      <p><strong>Proposition ID:</strong> {measure?.propositionId}</p>
      <p><strong>Legislative ID:</strong> <a href={measure?.legislativeUrl} target="_blank" rel="noopener noreferrer">{measure?.legislativeId}</a></p>
      <p><strong>Description:</strong> {measure?.description}</p>
      <p><strong>Analysis:</strong> <a href={measure?.analysisUrl} target="_blank" rel="noopener noreferrer">View Analysis</a></p>

      <div>
        <h3>Proposition Discussion</h3>
        <div>
          {conversationHistory.map((message, index) => (
            <p key={index} className={message.role}>{message.content}</p>
          ))}
        </div>
        <form className="chatform" onSubmit={handleUserMessageSubmit}>
          <input
            type="text"
            ref={inputRef} // Attach ref to input
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            placeholder="Answer: I agree, I disagree, Neutral, ask any question, or tell me your thoughts!"
            disabled={loading} // Disable input when loading
          />
          <button type="submit" disabled={loading || !userMessage.trim()}>Send</button> {/* Form submission button */}
        </form>
      </div>
    </div>
  );
};

export default PropQuest;

