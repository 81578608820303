// src/pages/BallotMeasuresPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SelectStateYear from '../components/SelectStateYear';
import { fetchBallotMeasures } from '../api';
import './BallotMeasuresPage.css';  // Import CSS file for styling

const BallotMeasuresPage = () => {
  const [ballotMeasures, setBallotMeasures] = useState(null);

  const handleFetchMeasures = async (state, year) => {
    const data = await fetchBallotMeasures(state, year);
    setBallotMeasures(data);
  };

  return (
    <div className="cleancenter">
      <div><SelectStateYear onSubmit={handleFetchMeasures} /></div>
      {ballotMeasures && ballotMeasures.propositions ? (
        <table className="ballot-table">
          <thead>
            <tr>
              <th>Option</th>
              <th>Proposition ID</th>
              <th>Legislative ID</th>
              <th>Description</th>
              <th>Analysis</th>
            </tr>
          </thead>
          <tbody>
            {ballotMeasures.propositions.map((measure, propindex) => (
              <tr key={propindex}>
                <td>
                  <Link
                    to={`/proposition-quest/${ballotMeasures.year}/${ballotMeasures.state}/${propindex}`}
                    state={{ measure, year: ballotMeasures.year, state: ballotMeasures.state, propindex: propindex }}
                  >
                    <button>Quest</button>
                  </Link>
                </td>
                <td>{measure.propositionId}</td>
                <td><a href={measure.legislativeUrl} target="_blank" rel="noopener noreferrer">{measure.legislativeId}</a></td>
                <td>{measure.description}</td>
                <td><a href={measure.analysisUrl} target="_blank" rel="noopener noreferrer">View Analysis</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-data-message">
          <header className="bg-transparent text-primary py-3 mb-4">
            <h3 className="text-center display-8" style={{ color: '#007bff' }}>
              AI Proposition Assistant.<br/>In the "Quest" I'll ask you questions, we can discuss, to help you determine how you would like to vote.
            </h3>
            <h3 className="text-center display-8" style={{ color: '#007bff' }}>
              <b>Why BallotQuest?</b>
            </h3>
            <p className="no-data-message2">I wanted to understand propositions based on their actual legal text, not influenced by potentially biased summaries. So, I compiled thousands of pages of legal documents into a database and use AI to help me navigate them by asking factual, unbiased questions or facilitating discussions to clarify the impact of voting for or against each proposition.
            </p>
            <h4>If you like this or have feedback contact me ryan@ballotquest.com</h4>
          </header>
        </p>
      )}
    </div>
  );
};

export default BallotMeasuresPage;

