// src/App.js
import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import BallotMeasuresPage from './pages/BallotMeasuresPage';
import PropQuest from './pages/PropQuest';
import './App.css';
import { initializeAnalytics, sendPageView } from './analytics';

// Initialize Google Analytics
initializeAnalytics();

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Send page view event whenever the location changes
    sendPageView(location.pathname);
  }, [location]);

  return (
    <div className="App">
      <header className="bg-primary text-white py-3 mb-4">
        <h1 className="text-center display-4">BallotQuest</h1>
        <nav>
          <Link to="/ballot-measures">Ballot Measures</Link>
        </nav>
      </header>
      <Routes>
        <Route path="/" element={<BallotMeasuresPage />} />
        <Route path="/ballot-measures" element={<BallotMeasuresPage />} />
        <Route path="/proposition-quest/:year/:state/:index" element={<PropQuest />} />
      </Routes>
    </div>
  );
};

export default App;
